<template>
  <v-sheet>
    <div class="d-flex justify-space-around flex-column flex-lg-row">
      <div>
        <div class="text-subtitle-2 ma-2 text-center font-weight-light">
          {{ $vuetify.lang.t("$vuetify.dashboard.productionInput") }}
          <v-btn class="ml-2" icon @click="toggleLinear1 = !toggleLinear1">
            <v-icon v-if="toggleLinear1"> mdi-chart-line </v-icon>
            <v-icon v-else> mdi-chart-bell-curve-cumulative </v-icon>
          </v-btn>
        </div>
        <v-sheet
          :elevation="2"
          :height="400"
          class="pa-3 d-flex justify-center overflow-y-auto"
        >
          <bar-chart
            class="mb-6"
            :show-legend="false"
            v-if="inputData && inputData.labels && inputData.labels.length > 0"
            :showTitle="true"
            :title="`Total Input ${totalInput}`"
            :data="inputData"
            horizontal
            :logarithmic="toggleLinear1"
            @click="showDetails($event, { processDirection: 'input' })"
            :responsive="true"
            :maintainAspectRatio="false"
            :height="calculateChartHeight(inputData.labels.length)"
          />
          <div v-else class="text-center text-caption font-light-weight">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </div>
        </v-sheet>
      </div>

      <div>
        <div class="text-subtitle-2 ma-2 text-center font-weight-light">
          {{ $vuetify.lang.t("$vuetify.dashboard.productionOutput") }}
          <v-btn class="ml-2" icon @click="toggleLinear2 = !toggleLinear2">
            <v-icon v-if="toggleLinear2"> mdi-chart-line </v-icon>
            <v-icon v-else> mdi-chart-bell-curve-cumulative </v-icon>
          </v-btn>
        </div>
        <v-sheet
          :elevation="2"
          :height="400"
          class="pa-3 d-flex justify-center overflow-y-auto"
        >
          <bar-chart
            class="mb-6"
            :show-legend="false"
            v-if="
              outputData && outputData.labels && outputData.labels.length > 0
            "
            :showTitle="true"
            :title="`Total Output ${totalOutput}`"
            :data="outputData"
            horizontal
            :logarithmic="toggleLinear2"
            @click="showDetails($event, { processDirection: 'output' })"
            :responsive="true"
            :maintainAspectRatio="false"
            :height="calculateChartHeight(outputData.labels.length)"
          />
          <div v-else class="text-center text-caption font-light-weight">
            {{ $vuetify.lang.t("$vuetify.noDataText") }}
          </div>
        </v-sheet>
      </div>
    </div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="showDetailsDialog"
      style="z-index: 1000"
    >
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="showDetailsDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <!-- Bar Chart for Daily Output -->
          <daily-distribution-chart :type="processDirection" :product="product" :startDate="startDate" :endDate="endDate"/>

          <infinite-scroll
            v-if="showDetailsDialog"
            :key="infiniteScrollKey"
            @fetchData="fetchData"
            :totalPages.sync="totalPages"
          >
            <div
              class="d-flex justify-center text-subtitle-1 mb-2"
              v-if="worksheets && worksheets.length > 0"
            >
              <div class="ml-2 text-caption">
                {{
                  $vuetify.lang.t(
                    "$vuetify.label.foundX",
                    totalSizes ? totalSizes : 0
                  )
                }}
              </div>
            </div>
            <v-divider />
            <worksheet-card
              v-for="(worksheet, index) in worksheets"
              :worksheet="worksheet"
              :index="index + 1"
              :key="worksheet ? worksheet.id : 'null'"
            />
          </infinite-scroll>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
const BarChart = () => import("@/components/charts/BarChart.vue");
import { calculateChartHeight } from "@/components/charts/utils.js";
const DailyDistributionChart = () => import('@/components/dashboard/DailyDistributionChart.vue');

export default {
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      showDetailsDialog: false,
      totalPages: null,
      worksheets: [],
      outputData: null,
      inputData: null,
      windowSize: { x: 800, y: 500 },
      ts: Date.now(),
      infiniteScrollKey: Date.now(),
      processTypeId: null,
      productId: null,
      productName: null,
      selectedValue: null,
      toggleLinear1: false,
      toggleLinear2: false,
      dailyOutputData: null,
    };
  },
  computed: {
    product(){
      return {
        id: this.productId,
        name: this.productName
      }
    }
  },
  components: {
    WorksheetCard,
    BarChart,
    DailyDistributionChart,
  },
  mounted() {
    this.initData();
  },
  watch: {
    startDate: function (newVal, oldVal) {
      this.initData();
    },
    endDate: function (newVal, oldVal) {
      this.initData();
    },
    showDetailsDialog(val) {
      if (!val) {
        console.log("dialog closed... reset values");
        this.worksheets = [];
        this.totalPages = null;
      } else {
        this.infiniteScrollKey = Date.now();
      }
    },
  },
  methods: {
    ...mapActions("processWorksheet", ["fetchProcessWorksheets"]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    calculateChartHeight(count) {
      return calculateChartHeight(count);
    },
    initData() {
      this.loadProductionSummary("Output").then((summary) => {
        this.outputData = summary;
        const total = summary.datasets[0].data.reduce(
          (sum, i) => (sum += i),
          0
        );
        this.totalOutput = this.$options.filters.formatNumber(total);
      });
      this.loadProductionSummary("Input").then((summary) => {
        this.inputData = summary;
        const total = summary.datasets[0].data.reduce(
          (sum, i) => (sum += i),
          0
        );
        this.totalInput = this.$options.filters.formatNumber(total);
      });
    },
    async loadProductionSummary(processDirection) {
      const params = {
        start: this.startDate,
        end: this.endDate,
      };
      return await this.$axios
        .get("/reports/manufacturing/production-summary/" + processDirection, {
          params,
        })
        .then((response) => {
          const dataset = response.data;
          dataset.sort((a, b) => b.netProductionWeight - a.netProductionWeight);
          const chartData = {
            labels: [],
            datasets: [
              {
                label: processDirection,
                backgroundColor: "#f87979",
                data: [],
                dataObject: [],
              },
            ],
          };

          dataset.forEach((d) => {
            const weight = this.$options.filters.formatInteger(
              d.netProductionWeight ? d.netProductionWeight : 0
            );
            chartData.labels.push(`${d.productName} (${weight})`);
            chartData.datasets[0].data.push(d.netProductionWeight);
            chartData.datasets[0].dataObject.push(d);
          });

          return chartData;
          // return this.list2Tree(processDirection, productionSummary);
        });
    },

    showDetails(item, { processDirection }) {
      this.dailyOutputData = null;
      if (item && item.data) {
        const productData = item.data;
        this.processTypeId = productData.processTypeId;
        this.productId = productData.productId;
        this.productName = productData.productName;
        this.processDirection = processDirection;
        this.showDetailsDialog = true;
      }

    },
    

    fetchData({ page, done, empty }) {
      const params = {
        page,
        size: 10,
        sort: "id,desc",
        processTypeId: this.processTypeId,
        processDirection: this.processDirection,
        productId: this.productId,
        start: this.startDate,
        end: this.endDate,
        statuses: "submitted,used",
      };

      this.$axios
        .get("/processWorksheet/by-process-time", { params })
        .then((resp) => {
          const newPage = resp.data.content;
          this.worksheets = this.worksheets.concat(newPage);
          this.totalPages = resp.data.totalPages;
          this.totalSizes = resp.data.totalElements;
          if (this.totalSizes > 0) {
            done();
          } else {
            empty();
          }
        })
        .catch((err) => {
          this.addErrorMessage(err);
        });
    },
  },
};
</script>